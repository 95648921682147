import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { getGlobalCssTheme } from "../redux/modules/session";
import PricePointCard from "./PricePointCard";

class PricePointList extends React.Component {
  state = {
    pricePoints: this.props.pricePoints,
    recommendedPricePointId: "",
    attemptedPurchasePricePointId: ""
  };

  componentDidMount() {
    if (this.props.storeCode === "2xko" && this.props.minVirtualAmount > 0) {
      this.setRecommendedPricePointId();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.pricePointsHaveChanged(this.props.pricePoints, nextProps.pricePoints)) {
      this.setState({ pricePoints: [] });
      // In order for react to register change in state we need to execute it on
      // a different context and give it a little delay.
      //  Otherwise clearing of items doesn't happen and there is
      // no flicker or animation of fade in effect.
      setTimeout(() => {
        this.setState({ pricePoints: nextProps.pricePoints });
        if (this.props.storeCode === "2xko" && this.props.minVirtualAmount > 0) {
          this.setRecommendedPricePointId();
        }
      }, 50);
    }
  }

  pricePointsHaveChanged(oldPricePoints, newPricePoints) {
    if (oldPricePoints.length !== newPricePoints.length) {
      return true;
    }

    const pricePointIds = oldPricePoints.map((item) => item.id);

    return newPricePoints.filter((item) => pricePointIds.indexOf(item.id) < 0).length > 0;
  }

  setRecommendedPricePointId() {
    // Find the first price point that is greater than or equal to the minVirtualAmount
    const pricePoint = this.state.pricePoints.find(
      (pricePoint) => pricePoint.virtualAmount + pricePoint.virtualBonusAmount >= this.props.minVirtualAmount
    );

    // If found, set the recommendedPricePointId with the id of the price point
    if (pricePoint) {
      this.setState({ recommendedPricePointId: pricePoint.id });
    }
  }

  setAttemptedPurchasePricePoint = (id) => {
    this.setState({ attemptedPurchasePricePointId: id });
  };

  render() {
    const { onPricePointClick } = this.props;

    return (
      <div
        className={classNames("order-items", {
          loaded: this.state.pricePoints.length > 0,
          "extra-price-points": this.state.pricePoints.length > 6
        })}
      >
        {this.state.pricePoints.map((pricePoint) => (
          <PricePointCard
            key={pricePoint.id}
            amount={pricePoint.realAmountCents}
            currency={pricePoint.realCurrencyCode}
            id={pricePoint.id}
            vc={pricePoint.virtualAmount}
            vcb={pricePoint.virtualBonusAmount}
            virtualCurrencyCode={pricePoint.virtualCurrencyCode}
            virtualCurrencyName={pricePoint.virtualCurrencyName}
            onPricePointClick={onPricePointClick}
            canStartPurchase={this.props.canStartPurchase}
            attemptStartPurchase={this.props.attemptStartPurchase}
            setAttemptedPurchasePricePoint={this.setAttemptedPurchasePricePoint}
            isAttemptedPurchasePricePoint={pricePoint.id === this.state.attemptedPurchasePricePointId}
            hasDoubleBonusVc={this.props.hasDoubleBonusVc}
            storeCode={this.props.storeCode}
            isRecommended={pricePoint.id === this.state.recommendedPricePointId}
            isSelected={pricePoint.id === this.props.selectedPurchasePricePointId}
          />
        ))}
      </div>
    );
  }
}

PricePointList.propTypes = {
  pricePoints: PropTypes.array.isRequired,
  onPricePointClick: PropTypes.func.isRequired,
  cssTheme: PropTypes.string,
  canStartPurchase: PropTypes.bool.isRequired,
  attemptStartPurchase: PropTypes.func.isRequired,
  hasDoubleBonusVc: PropTypes.bool.isRequired,
  storeCode: PropTypes.string.isRequired,
  minVirtualAmount: PropTypes.number,
  selectedPurchasePricePointId: PropTypes.string
};

const mapStateToProps = (state) => ({
  cssTheme: getGlobalCssTheme(state)
});

export default connect(mapStateToProps, null)(PricePointList);
