import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PrepaidCodeWindow from "./PrepaidCodeWindow";
import {
  confirmPricePointPrepaidPurchase,
  confirmPricePointPrepaidPurchaseV2,
  clearPrepaidResults,
  getGame,
  getPlatform,
  getPrepaidSuccess,
  getPrepaidFailureCode,
  getPrepaidInvalidReasonCode,
  loadPaymentOptions,
  loadPaymentOptionsV2,
  getOptionsLoadingState
} from "../redux/modules/paymentOptions";
import { getSessionToken, getSessionUseApiV2 } from "../redux/modules/session";
import { getCurrentCountry } from "../redux/modules/user";

class PrepaidMode extends React.Component {
  componentDidMount() {
    if (this.props.useApiV2) {
      this.props.loadPaymentOptionsV2();
    } else {
      this.props.loadPaymentOptions();
    }
  }

  render() {
    if (this.props.prepaidSuccess) {
      return <Redirect to="/checkout-success" />;
    }

    const loadingContainer = (
      <div className="container loading">
        <div className="loading-spinner" />
      </div>
    );

    const confirmPricePointPrepaidPurchase = this.props.useApiV2
      ? this.props.confirmPricePointPrepaidPurchaseV2
      : this.props.confirmPricePointPrepaidPurchase;

    const prepaidCodeWindowContainer = (
      <div className="main">
        <div className="main-content">
          <PrepaidCodeWindow
            confirmPricePointPrepaidPurchase={confirmPricePointPrepaidPurchase}
            sessionToken={this.props.sessionToken}
            prepaidFailureCode={this.props.prepaidFailureCode}
            prepaidInvalidReasonCode={this.props.prepaidInvalidReasonCode}
            clearPrepaidResults={this.props.clearPrepaidResults}
            country={this.props.currentCountry}
            platform={this.props.platform}
            game={this.props.game}
          />
        </div>
      </div>
    );

    return (
      <div className="payment-flow">
        <div className="container payment-options fade-in">
          {this.props.loading ? loadingContainer : prepaidCodeWindowContainer}
        </div>
      </div>
    );
  }
}

PrepaidMode.propTypes = {
  prepaidSuccess: PropTypes.bool,
  prepaidFailureCode: PropTypes.string,
  prepaidInvalidReasonCode: PropTypes.string,
  clearPrepaidResults: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchaseV2: PropTypes.func.isRequired,
  sessionToken: PropTypes.string.isRequired,
  currentCountry: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadPaymentOptions: PropTypes.func.isRequired,
  loadPaymentOptionsV2: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  game: PropTypes.string.isRequired,
  useApiV2: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  prepaidSuccess: getPrepaidSuccess(state),
  prepaidFailureCode: getPrepaidFailureCode(state),
  prepaidInvalidReasonCode: getPrepaidInvalidReasonCode(state),
  sessionToken: getSessionToken(state),
  useApiV2: getSessionUseApiV2(state),
  currentCountry: getCurrentCountry(state),
  loading: getOptionsLoadingState(state),
  platform: getPlatform(state),
  game: getGame(state)
});

export default connect(
  mapStateToProps,
  {
    confirmPricePointPrepaidPurchase,
    confirmPricePointPrepaidPurchaseV2,
    clearPrepaidResults,
    loadPaymentOptions,
    loadPaymentOptionsV2
  }
)(PrepaidMode);
